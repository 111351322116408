import React from 'react'

const Testimonial = ({ data }) => {
  return (
    <>
      <p>
        <strong>{data.frontmatter.date}</strong>
      </p>
      <div className="testimonial-content" dangerouslySetInnerHTML={{ __html: data.html }} />
      <p>{data.frontmatter.name}</p>
      <hr />
    </>
  )
}

export default Testimonial
