import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Testimonial from '../components/testimonial'

const Testimonials = props => {
  console.log(props)
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? '/testimonials/' : `/testimonials/${(currentPage - 1).toString()}`
  const nextPage = `/testimonials/${(currentPage + 1).toString()}`
  return (
    <Layout>
      <SEO title="Testimonials" />
      <div className="container container-last">
        <h1>Testimonials</h1>
        {props.data.allMarkdownRemark.edges.map(({ node }) => (
          <Testimonial key={node.id} data={node} />
        ))}
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            listStyle: 'none',
          }}
        >
          {!isFirst && (
            <Link to={prevPage} rel="prev">
              ← Previous Page
            </Link>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <li
              key={`pagination-number${i + 1}`}
              style={{
                margin: 0,
                padding: `0 1em`,
              }}
            >
              <Link
                to={`/testimonials/${i === 0 ? '' : i + 1}`}
                style={{
                  color: i + 1 === currentPage ? '#666' : '',
                  textDecoration: i + 1 === currentPage ? 'none' : 'underline',
                }}
              >
                {i + 1}
              </Link>
            </li>
          ))}
          {!isLast && (
            <Link to={nextPage} rel="next">
              Next Page →
            </Link>
          )}
        </ul>
      </div>
    </Layout>
  )
}

export default Testimonials

export const pageQuery = graphql`
  query testimonialPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            date(formatString: "MMMM DD, YYYY")
          }
          html
        }
      }
    }
  }
`
